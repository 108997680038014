import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { SdlToggleSidebarComponent } from './sdl-toggle-sidebar.component';

@NgModule({
  declarations: [
    SdlToggleSidebarComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    SdlToggleSidebarComponent,
  ]
})
export class SdlToggleSidebarModule { }
