import { Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { StrategyFunction } from '../service/configuration.service';

@Directive({
    selector: '[SdlPermissionsOnly],[SdlPermissionsExcept]'
})
export class SdlPermissionsRestrictStubDirective implements OnInit {

    @Input() SdlPermissionsOnly: string | string[];
    @Input() SdlPermissionsOnlyThen: TemplateRef<any>;
    @Input() SdlPermissionsOnlyElse: TemplateRef<any>;

    @Input() SdlPermissionsExcept: string | string[];
    @Input() SdlPermissionsExceptElse: TemplateRef<any>;
    @Input() SdlPermissionsExceptThen: TemplateRef<any>;

    @Input() SdlPermissionsThen: TemplateRef<any>;
    @Input() SdlPermissionsElse: TemplateRef<any>;

    @Input() SdlPermissionsOnlyAuthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsOnlyUnauthorisedStrategy: string | StrategyFunction;

    @Input() SdlPermissionsExceptUnauthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsExceptAuthorisedStrategy: string | StrategyFunction;

    @Input() SdlPermissionsUnauthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsAuthorisedStrategy: string | StrategyFunction;

    @Output() permissionsAuthorized = new EventEmitter();
    @Output() permissionsUnauthorized = new EventEmitter();


    constructor(private viewContainer: ViewContainerRef) {}


    ngOnInit(): void {
        this.viewContainer.clear();
        if (this.getUnAuthorizedTemplate()) {
            this.viewContainer.createEmbeddedView(this.getUnAuthorizedTemplate());
        }
        this.permissionsUnauthorized.emit();
    }


    private getUnAuthorizedTemplate() {
        return this.SdlPermissionsOnlyElse ||
            this.SdlPermissionsExceptElse ||
            this.SdlPermissionsElse;
    }

}
