import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';


export function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return (val === 'true' || val === '');
  }

  return !!val;
}

@Component({
  selector: 'sdl-user',
  templateUrl: './sdl-user.component.html',
  styleUrls: ['./sdl-user.component.scss']
})
export class SdlUserComponent {

  imageBackgroundStyle: SafeStyle;

  @Input() name: string = 'Anonymous';
  @Input() subtitle: string;
  @Input() badgeValue: string;
  @Input()
  set picture(value: string) {
    this.imageBackgroundStyle = value ? this.domSanitizer.bypassSecurityTrustStyle(`url(${value})`) : null;
  }

  @Input()
  get showInitials(): boolean {
    return this._showInitials;
  }
  set showInitials(val: boolean) {
    this._showInitials = convertToBoolProperty(val);
  }
  private _showInitials: boolean = true;
  static ngAcceptInputType_showInitials: boolean;

  @Output() onLogout = new EventEmitter();
  constructor(private domSanitizer: DomSanitizer) { }

  getInitials(): string {
    if (this.name) {
      const names = this.name.split(' ');
      return names.map(n => n.charAt(0)).splice(0, 2).join('').toUpperCase();
    }
    return '';
  }

  logout(){
    this.onLogout.next();
  }
}
