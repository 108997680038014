import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'sdl-alert',
  templateUrl: './sdl-alert.component.html',
  styleUrls: ['./sdl-alert.component.scss'],

})
export class SdlAlertComponent  {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
   }

   closeSnackbar() {
    this.data.snackBar.dismiss();
  }
}
