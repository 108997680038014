import { Component, Input, AfterContentChecked } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';

@Component({
    selector: 'mdt-status-cell',

    templateUrl: './icon-cell.component.html'

})
export class IconCellComponent implements CellComponent, AfterContentChecked {
    @Input() column: ColumnConfig;
    @Input() row: object;
    colorClass: string;
    constructor() {}

    ngAfterContentChecked(): void {
        this.colorClass = "text-danger";
        let tipo = this.row["notTipo"];

        switch (tipo) {
            case 0:
                this.colorClass = "sdl-cell-notification-info";
                break;
            case 2:
                this.colorClass = "sdl-cell-notification-danger";
                break;
            case 1:
                this.colorClass = "sdl-cell-notification-warning";
                break;
            case 3:
                this.colorClass = "sdl-cell-notification-success";
                break;
        }
    }
}