import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { ToolbarService } from 'src/app/core/services/toolbar/toolbar.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Page } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'sdl-header',
  templateUrl: './sdl-header.component.html',
  styleUrls: ['./sdl-header.component.scss']
})
export class SdlHeaderComponent implements OnInit {
  @Input() activePage: Page= {description:"Home",isChild:false,title:"Home"};
  @Input() previousUrl: string[];
  user: any;
  debounceTime: number = 200;
  loading: boolean = false;
  loadingSubscription: Subscription;
  constructor(
    private authService: AuthService,
    private router: Router,
    private toolService: ToolbarService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.user = {
      name: '',
      //picture: '../../../../assets/user.png',
      subtitle: ''
    }

    this.authService.usersSubject.subscribe(res => {

      let roles = "";

      if (typeof res.role === 'string')
        roles = res.role;
      else {
        res.role.forEach(element => {
          roles += element + " | ";
        });
        roles.substring(0, roles.length - 2)
      }
      this.user = {
        name: res.NombreLargo,
        //picture: '../../../../assets/user.png',
        subtitle: roles
      }
    });
  }

  public onLogout() {
    this.authService.logout();

  }
  ngAfterViewInit(): void {
    this.loadingSubscription = this.toolService.loadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
      (status: boolean) => {
        this.loading = status;
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
