import { Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { StrategyFunction } from '../service/configuration.service';

@Directive({
    selector: '[SdlPermissionsOnly],[SdlPermissionsExcept]'
})
export class SdlPermissionsAllowStubDirective implements OnInit {

    @Input() SdlPermissionsOnly: string | string[];
    @Input() SdlPermissionsOnlyThen: TemplateRef<any>;
    @Input() SdlPermissionsOnlyElse: TemplateRef<any>;

    @Input() SdlPermissionsExcept: string | string[];
    @Input() SdlPermissionsExceptElse: TemplateRef<any>;
    @Input() SdlPermissionsExceptThen: TemplateRef<any>;

    @Input() SdlPermissionsThen: TemplateRef<any>;
    @Input() SdlPermissionsElse: TemplateRef<any>;

    @Input() SdlPermissionsOnlyAuthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsOnlyUnauthorisedStrategy: string | StrategyFunction;

    @Input() SdlPermissionsExceptUnauthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsExceptAuthorisedStrategy: string | StrategyFunction;

    @Input() SdlPermissionsUnauthorisedStrategy: string | StrategyFunction;
    @Input() SdlPermissionsAuthorisedStrategy: string | StrategyFunction;

    @Output() permissionsAuthorized = new EventEmitter();
    @Output() permissionsUnauthorized = new EventEmitter();


    constructor(private viewContainer: ViewContainerRef,
                private templateRef: TemplateRef<any>) {}


    ngOnInit(): void {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.getAuthorizedTemplate());
        this.permissionsUnauthorized.emit();
    }


    private getAuthorizedTemplate() {
        return this.SdlPermissionsOnlyThen ||
            this.SdlPermissionsExceptThen ||
            this.SdlPermissionsThen ||
            this.templateRef;
    }

}
