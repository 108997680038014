import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LogoModule } from '../logo/logo.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule, MatMenuModule, MatProgressBarModule } from '@angular/material';
import { SdlUserModule } from '../sdl-user/sdl-user.component.module';
import { SdlSidebarModule } from './sdl-sidebar/sdl-sidebar.module';
import { SdlToggleSidebarModule } from './sdl-toggle-sidebar/sdl-toggle-sidebar.module';
import { SdlMenuComponent } from './sdl-menu.component';
import { SdlHeaderComponent } from './sdl-header/sdl-header.component';
import { SdlHeaderModule } from './sdl-header/sdl-header.module';


@NgModule({
    declarations: [SdlMenuComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        LogoModule,
        MatMenuModule,
        FlexLayoutModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatProgressBarModule,
        MatButtonModule,
        MatExpansionModule,
        SdlUserModule,
        SdlSidebarModule,
        SdlToggleSidebarModule,
        SdlHeaderModule
    ],
})
export class SdlMenuModule {}
