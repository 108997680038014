import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class ConfigService {
    config: any;

    constructor(private http: HttpClient) {}

    loadConfig() {
      return this.http
        .get<any>('assets/setting/config.json')
        .toPromise()
        .then(config => {
          this.config = config;
          environment.apiUrl=config.apiUrl;
        });
    }
  }