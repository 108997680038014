export class ColumnConfig {
    name: string;
    displayName?: string;
    type: string;
    options?: any;
    sticky?: string;
    sort?: boolean;
    sortActive?: boolean;
    sortDirection?:string; //asc o desc
    align?:string; //start / center / end
    width?:string;
    optionConfig?:optionConfig;
}

interface optionConfig{
    Eliminar:boolean;
    Editar:boolean;
    dateFormat?:string;
}