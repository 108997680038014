import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ColumnConfig } from './column-config.model.ts';
import { ColumnFilterService } from './table-cell/cell-types/column-filter.service';
import { ColumnFilter } from './column-filter.model';
import { DataSource } from '@angular/cdk/table';
import { MatPaginator, MatSort, MatDialog, MatDialogConfig, Sort, MatTableDataSource, MatSortable } from '@angular/material';



const ELEMENT_DATA: any[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'sdl-table',
  templateUrl: './sdl-table.component.html',
  styleUrls: ['./sdl-table.component.scss'],

})
export class SdlTableComponent implements OnInit {

  @Input() columns: ColumnConfig[];
  @Input() dataSource: DataSource<any>;
  @Input() pageSize = 0;
  @Input() totalNumberOfRecords = 0;
  @Input() pageNumber = 0;
  @Input() showFilters = true;
  @Input() stickyHeader = false;
  @Input() Pagination = false;
  @Input() ExportButton = false;
  @Input() PdfButton = false;
  @Input() PrintButton = false;
  @Input() AddButton = false;
  @Input() isPaginatorLitle=true;
  //

  @Output() rowClick = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onView = new EventEmitter<any>();
  @Output() onSaveEdit = new EventEmitter<any>();

  @Output() onAdd = new EventEmitter();
  @Output() onPrint = new EventEmitter();
  @Output() onExcel = new EventEmitter();
  @Output() onPdf = new EventEmitter();
  @Output() onPage = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  @Input() isLoading = true;
  displayedColumns: string[];

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) private internalPaginator: MatPaginator;

  private appliedFilters: { [key: string]: any; } = {};


  constructor(private readonly columnFilterService: ColumnFilterService, private readonly dialog: MatDialog,private cdref: ChangeDetectorRef) {


  }

  ngOnInit() {
    if (this.dataSource == null) {
      throw Error('No se han seteado los datos.');
    }
    if (this.columns == null) {
      throw Error('No se han seteado las columnas.');
    }

    this.columns.forEach((column, index) => column.name = this.prepareColumnName(column.name, index));
    this.displayedColumns = this.columns.map((column, index) => column.name);

  }

  onSortData(sort: Sort) {
    if (sort.active && sort.direction !== '') {
      this.onSort.next(sort);

    }
  }
  ngAfterViewInit() {
    if(this.Pagination){
    this.internalPaginator._intl.itemsPerPageLabel = 'Cantidad de registros por pág.:';
    this.internalPaginator._intl.firstPageLabel = 'Primero';

    this.internalPaginator._intl.lastPageLabel = 'Último';
    this.internalPaginator._intl.nextPageLabel = 'Siguiente';
    this.internalPaginator._intl.previousPageLabel = 'Anterior';
    this.internalPaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

    if (this.dataSource !== null) {
      const dataSource = this.dataSource as any;
      let indx = this.columns.findIndex(x => x.sortActive);
      if (indx >= 0) {
        this.sort.sort({
          id: this.columns[indx].name,
          start: this.columns[indx].sortDirection as any,
          disableClear: true,
        });

        // ugly hack!
        const activeSortHeader = this.sort.sortables.get(this.columns[indx].name);
        activeSortHeader['_setAnimationTransitionState']({
          fromState: this.sort.direction,
          toState: 'active',
        });
      }
      dataSource.sort = this.sort;
      dataSource.paginator = this.internalPaginator;
    }
    this.cdref.detectChanges();
  }


  isUsingInternalPaginator() {

    return this.internalPaginator === this.internalPaginator;
  }

  canFilter(column: ColumnConfig) {
    const filter = this.columnFilterService.getFilter(column.type);
    return filter != null;
  }

  isFiltered(column: ColumnConfig) {
    return this.appliedFilters[column.name];
  }

  getHeaderPosition(column: ColumnConfig){
    return "text-" + column.align;
  }

  getFilterDescription(column: ColumnConfig) {
    const filter = this.appliedFilters[column.name];
    if (!filter || !filter.getDescription) {
      return null;
    }

    return filter.getDescription();
  }

  prepareColumnName(name: string | undefined, columnNumber: number) {
    return name || 'col' + columnNumber;
  }

  filter(column: ColumnConfig) {
    const filter = this.columnFilterService.getFilter(column.type);

    if (filter) {
      const dialogConfig = new MatDialogConfig();
      const columnFilter = new ColumnFilter();
      columnFilter.column = column;

      if (this.appliedFilters[column.name]) {
        columnFilter.filter = Object.create(this.appliedFilters[column.name]);
      }

      dialogConfig.data = columnFilter;

      const dialogRef = this.dialog.open(filter, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.appliedFilters[column.name] = result;
        } else if (result === '') {
          delete this.appliedFilters[column.name];
        }

        if (result || result === '') {
          this.updateDataSource();
        }
      });
    }
  }

  clearFilters() {
    this.appliedFilters = {};
    this.updateDataSource();
  }

  protected updateDataSource() {
    const dataSource = this.dataSource as any;
    dataSource.filters = this.getFilters();
  }

  getFilters() {
    const filters = this.appliedFilters;
    const filterArray = Object.keys(filters).map((key) => filters[key]);
    return filterArray;
  }

  getFilter(columnName: string): any {
    const filterColumn = this.getColumnByName(columnName);

    if (!filterColumn) {
      throw Error(`Column with name '${columnName}' does not exist.`);
    }

    return this.appliedFilters[filterColumn.name];
  }

  setFilter(columnName: string, filter: any) {
    const filterColumn = this.getColumnByName(columnName);

    if (!filterColumn) {
      throw Error(`Cannot set filter for a column. Column with name '${columnName}' does not exist.`);
    }

    this.appliedFilters[filterColumn.name] = filter;
    this.updateDataSource();
  }

  private getColumnByName(columnName: string): ColumnConfig | undefined {
    return this.columns.find(c =>
      (c.name ? c.name.toLowerCase() : c.name) === (columnName ? columnName.toLowerCase() : columnName)
    );
  }

  edit(row: any) {
    this.onEdit.next(row);
  }

  view(row: any) {
    this.onView.next(row);
  }

  remove(row: any) {
    this.onRemove.next(row);
  }

  onRowClick(row: any) {

    this.rowClick.next(row);
  }

  SaveEdit(row:any){
    this.onSaveEdit.next(row);
  }

  Add() {

    this.onAdd.next();
  }
  export() {

    this.onExcel.next();
  }
  pdf() {

    this.onPdf.next();
  }
  print() {

    this.onPrint.next();
  }

  page(page) {

    this.onPage.next(page);
  }

}