import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ToolbarService {

  private _loading: boolean = false;
  loadingStatus: Subject<boolean> = new Subject();

  constructor(@Inject(DOCUMENT) private document: Document) {

  }

  get loading():boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

}
