import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';
@Component({
    selector: 'ld-options-cell',
    templateUrl: './options-cell.component.html'
})
export class OptionsCellComponent implements CellComponent, OnInit {
    @Input()
    column: ColumnConfig;

    @Input()
    row: any;

    btnEdit: boolean = false;
    btnRemove: boolean = false;

    //Emite un evento callback
    @Output() onEdit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();
    @Output() onView = new EventEmitter<any>();


    constructor() {

    }
    ngOnInit(): void {
        if (this.column) {
            this.btnEdit = this.column.optionConfig ? this.column.optionConfig.Editar : false;
            this.btnRemove = this.column.optionConfig ? this.column.optionConfig.Eliminar : false;

        }
    }


    Edit() {
        this.onEdit.next(this.row);
    }

    Remove() {
        this.onRemove.next(this.row);
    }
    View() {
        this.onView.next(this.row);
    }
}