import { Component, Input, Output, EventEmitter, AfterContentInit, AfterViewChecked, AfterViewInit, AfterContentChecked } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';



@Component({
    selector: 'ld-input-title-cell',
    templateUrl: './input-title-cell.component.html',
})
export class InputTitleCellComponent implements CellComponent,AfterContentChecked  {
    @Input()
    column: ColumnConfig;

    @Input()
    row: any;

    title:string;
    subtitle:string;
    constructor() {
    }

    ngAfterContentChecked (): void {
        this.title = this.row.notTitulo
        this.subtitle = this.row.notSubtitulo
    }
}