import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TimeoutError } from 'rxjs';
import { SdlAlertComponent } from '../../components/sdl-alert/sdl-alert.component';

export class errItem {
  code: number;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) { }

  default(message: string) {
    this.show(message, 'info', 'default-notification-overlay', 2500, true);
  }

  info(message: string, btnClose?: boolean) {
    this.show(message, 'info', 'info-notification-overlay', !btnClose ? 2500 : 0, btnClose);
  }

  success(message: string, btnClose?: boolean) {
    this.show(message, 'check_circle', 'success-notification-overlay', !btnClose ? 2500 : 0, btnClose);
  }

  warn(message: string, btnClose?: boolean) {
    this.show(message, 'warning', 'warning-notification-overlay', !btnClose ? 2500 : 0, !btnClose);
  }

  error(message: string) {

    this.show(message, 'error', 'error-notification-overlay', 0, true);
  }

  private show(message: string, icon: string, sclass: string, duration: number, btnClose: boolean) {
    this.snackBar.openFromComponent(SdlAlertComponent, {
      data: {
        message: message,
        snackBar: this.snackBar,
        icon: icon,
        btnClose: btnClose
      },
      duration: duration,
      panelClass: [sclass],
    });
  }

  clear() {
    this.snackBar.dismiss();
  }

  isTimeOut(err) {
    return err instanceof TimeoutError;
  }


  getErrors(err): errItem[] {

    if (!err) return;

    if (this.isTimeOut(err)) return;

    if (err.error) {
      if (err.error.errors) {
        if (err.error.errors instanceof Array) {
          this.show(err.error.errors[0].description, 'error', 'error-notification-overlay', 0, true);
        }
        else {
          this.show(err.error.description, 'error', 'error-notification-overlay', 0, true);
        }

      }
      else {




        switch (err.error.code) {
          case '1000':
            this.show(err.error.description, 'info', 'info-notification-overlay', 0, true);
            break;
          case '1006':
            this.show(err.error.description, 'info', 'info-notification-overlay', 0, true);
            break;
          case '1012':
            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          case '1013':
            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          case '1014':

            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          case '1015':
            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          case '1016':

            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          case '1020':
            this.show(err.error.description, 'info', 'info-notification-overlay', 0, true);
            break;
          case '1030':
            this.show(err.error.description, 'warning', 'warning-notification-overlay', 0, true);
            break;
          default:




            this.show('Estamos presentando demoras en la atención. Por favor intenta nuevamente más tarde y de persistir infórmanos a través de la pagina de contacto.', 'error', 'error-notification-overlay', 0, true);
            break;
        }
      }
    }
    else {

      switch (err.code) {
        case '-1000':
          this.show(err.description, 'warning', 'warning-notification-overlay', 0, true);
          break;
        default:
          if (err.description)
            this.show(err.description, 'error', 'error-notification-overlay', 0, true);
          else
            this.show("Ha ocurrido un error.", 'error', 'error-notification-overlay', 0, true);
          break;
      }
    }


  }
}
