import { Component, OnInit } from '@angular/core';
import { SdlMenuService } from 'src/app/core/services/sdl-menu/sdl-menu.service';

@Component({
  selector: 'sdl-toggle-sidebar',
  templateUrl: './sdl-toggle-sidebar.component.html',
  styleUrls: ['./sdl-toggle-sidebar.component.scss']
})
export class SdlToggleSidebarComponent implements OnInit {


  constructor(
    private sidebarService: SdlMenuService,
  ) { }

  ngOnInit() { }

  toggleSideNav() {
    this.sidebarService.toggle();
  }

}
