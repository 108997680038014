
import {
  Directive,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  Input
} from "@angular/core";
import { MatPaginator } from "@angular/material";

@Directive({
  selector: "[style-paginator]"
})
export class StylePaginatorDirective {
  private _currentPage = 1;
  private _pageGapTxt = "...";
  private _rangeStart;
  private _rangeEnd;
  public directiveLoaded = false;
  private _buttons = [];
  @Input() length: number;
  @Input() pageSize: number;

  @Input()
  get showTotalPages(): number { return this._showTotalPages; }
  set showTotalPages(value: number) {
    this._showTotalPages = value % 2 == 0 ? value + 1 : value;
  }
  private _showTotalPages = 5;
  private botones: number[] = [];
  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    this.matPag.page.subscribe((v) => {
      this._currentPage =v.pageIndex;
      this.matPag.pageIndex = v.pageIndex;
      this.initPageRange();
    })
  }

  private buildPageNumbers() {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-range-actions"
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-next"
    );
    const prevButtonCount = this._buttons.length;

    // remove buttons before creating new ones
    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.ren.removeChild(actionContainer, button);
      });
      //Empty state array
      this._buttons.length = 0;
    }

    //initialize next page and last page buttons
    if (this._buttons.length == 0) {
      let nodeArray = this.vr.element.nativeElement.childNodes[0].childNodes[0]
        .childNodes[2].childNodes;

    }
    this.botones = [];
    for (let i = 0; i < this.matPag.getNumberOfPages(); i = i + 1) {
      if (
        (i < this._showTotalPages && this._currentPage < this._showTotalPages && i > this._rangeStart) ||
        (i >= this._rangeStart && i <= this._rangeEnd)
      ) {
        this.botones.push(i);
      }
    }



    if (this.botones.length < this._showTotalPages) {
      let min = Math.min.apply(null, this.botones);
      let btnlength = this.botones.length;

      if(btnlength===0)
       this.botones.push(0);
      else{
      for (let index = 1; index <= (this._showTotalPages - btnlength); index++) {

        if (min - index > 0)
          this.botones.push(min - index);
      }
    }

      this.botones = this.botones.sort((n1, n2) => n1 - n2);
    }
    this.botones.forEach(i => {
      this.ren.insertBefore(
        actionContainer,
        this.createButton(i, this.matPag.pageIndex),
        nextPageNode
      );
    });
  }

  private createButton(i: any, pageIndex: number): any {
    const linkBtn = this.ren.createElement("sdl-button-pag");
    this.ren.addClass(linkBtn, "sdl-button-pag");

    const pagingTxt = isNaN(i) ? this._pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + "");

    this.ren.addClass(linkBtn, "mat-custom-page");
    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, "disabled", "disabled");
        this.ren.addClass(linkBtn, 'mat-custom-page-selected');

        break;
      case this._pageGapTxt:
        this.ren.listen(linkBtn, "click", () => {
          this.switchPage(this._currentPage + this._showTotalPages);
        });
        break;
      default:
        this.ren.listen(linkBtn, "click", () => {
         this.switchPage(i);
        });
        break;
    }

    this.ren.appendChild(linkBtn, text);
    //Add button to private array for state
    this._buttons.push(linkBtn);
    return linkBtn;
  }

  private initPageRange(): void {
    this._rangeStart = this._currentPage - this._showTotalPages / 2;
    this._rangeEnd = this._currentPage + this._showTotalPages / 2;
    this.buildPageNumbers();
  }

  private switchPage(i: number): void {
    this._currentPage = i;
    this.matPag.pageIndex = i;
    this.initPageRange();
    this.matPag._changePageSize(this.matPag.pageSize);


  }

  public ngAfterViewInit() {

    setTimeout(() => {
      this.directiveLoaded = true;
    }, 500);
  }

  public ngDoCheck() {
    if (this.directiveLoaded) {
      this.initPageRange();
    }
  }
}