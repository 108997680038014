import {
  Directive,
  Host,
  HostListener,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';

@Directive({
  selector: '[litePaginator]'
})
export class StylePaginatorLiteDirective {
  @HostListener('page', ['$event'])
  onChange(event: PageEvent) {
    this.initPageRange();
  }

  constructor(
    @Host() @Self() @Optional() private matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    // inspired by
    // https://stackoverflow.com/questions/53646259/how-to-customize-mat-paginator-in-angular-material
    // https://stackblitz.com/edit/angular-wyx2ue?embed=1&file=app/paginator-overview-example.html
    setTimeout(() => {
      this.initPageRange();
    }, 0);
  }

  private initPageRange(): void {
    const pageRange = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions > div.mat-paginator-range-label'
    );
    pageRange.innerHTML = '';
    const pageCount = this.pageCount(
      this.matPag.length,
      this.matPag.pageSize
    );

    const bttonFirst = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions > button.mat-paginator-navigation-first'
    );

    const bttonLast = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions > button.mat-paginator-navigation-last'
    );
    const pag = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions'
    );

    if(this.ren && bttonFirst && bttonLast){
    this.ren.removeChild(pag, bttonFirst);
    this.ren.removeChild(pag, bttonLast);
    }

  }



  private pageCount(length: number, pageSize: number): number {
    return Math.floor(length / pageSize) + 1;
  }

  private switchPage(i: number): void {
    this.matPag.pageIndex = i;
    this.matPag._changePageSize(this.matPag.pageSize);
  }
}
