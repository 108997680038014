import { Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SdlPermissionsGuard, SdlPermissionsService, SdlPermissionsRouterData } from './permission';


export interface NavRoute extends Route {
    path?: string;
    icon?: string;
    group?: string;
    iconGroup?: string;
    key?: string;
    description?: string;
    groupedNavRoutes?: NavRoute[];
}

export const sideNavPath = 'site';

export const navRoutes: NavRoute[] = [
    {
        path: '',
        redirectTo:'home',
        pathMatch: 'full',
    },
    {
        data: {
            title: 'Consulta de Cuentas de Clientes', description: 'Consulta de Cuentas de Clientes', permissions: {
                only: ['Permissions.CliBusqueda.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'lock',
        path: 'customer-search',
        group: 'Gestión',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'settings',
        loadChildren: () =>
            import('./pages/customer-search/customer-search.module').then(
                m => m.CustomerSearchModule,
            ),
    },
    {
        data: {
            title: 'Notificaciones', description: 'Notificaciones de Usuarios', permissions: {
                only: ['Permissions.Notificaciones.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'lock',
        path: 'notificaciones',
        group: 'Gestión',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'settings',
        loadChildren: () =>
            import('./pages/notificaciones-page/notificaciones-page.module').then(
                m => m.NotificacionesPageModule,
            ),
    },
    {

        data: {
            title: 'Usuarios', description: 'Gestión de Usuarios', permissions: {
                only: ['Permissions.Usuarios.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            }
        },
        icon: 'group',
        path: 'users',
        group: 'Configuración',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'tune',
        loadChildren: () =>
            import('./pages/users/users.module').then(
                m => m.UsersModule,
            ),
    },
    {
        data: {
            title: 'Roles', description: 'Roles de Usuarios', permissions: {
                only: ['Permissions.UsrRoles.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'lock',
        path: 'roles',
        group: 'Configuración',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'tune',
        loadChildren: () =>
            import('./pages/user-roles-page/user-roles-page.module').then(
                m => m.UserRolesModule,
            ),
    },
    {
        data: {
            title: 'Parámetros', description: 'Parámetros Generales', permissions: {
                only: ['Permissions.Parametros.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }

            } as SdlPermissionsRouterData
        },
        icon: 'settings',
        path: 'parametros',
        group: 'Configuración',
        iconGroup: 'tune',
        canLoad: [SdlPermissionsGuard],
        loadChildren: () =>
            import('./pages/parameters-page/parameters-page.module').then(
                m => m.ParametersModule,
            ),
    },
    {
        data: {
            title: 'Auditoría Usuarios', description: 'Auditoría de Usuarios',
            permissions: {
                only: ['Permissions.UsrAuditoria.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }

            }
        },
        icon: 'find_replace',
        path: 'auditoria-usuarios',
        canLoad: [SdlPermissionsGuard],
        group: 'Reportes',
        iconGroup: 'find_in_page',
        loadChildren: () =>
            import('./pages/user-audit/user-audit.module').then(
                m => m.UserAuditModule,
            ),
    },
    {
        data: {
            title: 'Auditoría Clientes', description: 'Auditoría de Clientes',
            permissions: {
                only: ['Permissions.CliAuditoria.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }

            }
        },
        icon: 'find_replace',
        path: 'auditoria-clientes',
        canLoad: [SdlPermissionsGuard],
        group: 'Reportes',
        iconGroup: 'find_in_page',
        loadChildren: () =>
            import('./pages/clientes-audit/clientes-audit.module').then(
                m => m.ClientesAuditModule,
            ),
    },

    {
        data: {
            title: 'Seguimiento de Solicitudes', description: 'Seguimiento de Solicitudes', permissions: {
                only: ['Permissions.SegSolicitudes.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'assignment',
        path: 'tracking-request',
        group: 'Reportes',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'assignment',
        loadChildren: () =>
            import('./pages/tracking-request/tracking-request.module').then(
                m => m.TrackingRequestModule,
            ),
    },
    {
        data: {
            title: 'Listado de Accesos', description: 'Listado de Accesos', permissions: {
                only: ['Permissions.LstLogins.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'assignment',
        path: 'login-report',
        group: 'Reportes',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'assignment',
        loadChildren: () =>
            import('./pages/login-report/login-report.module').then(
                m => m.LoginReportModule,
            ),
    },
    {
        data: {
            title: 'Listado de Cuentas', description: 'Listado de Cuentas', permissions: {
                only: ['Permissions.LstCuentas.View'],
                redirectTo: {
                    navigationCommands: ["/"+sideNavPath +'/403'],

                }
            } as SdlPermissionsRouterData
        },
        icon: 'assignment',
        path: 'account-report',
        group: 'Reportes',
        canLoad: [SdlPermissionsGuard],
        canActivateChild: [SdlPermissionsGuard],
        iconGroup: 'assignment',
        loadChildren: () =>
            import('./pages/account-report/account-report.module').then(
                m => m.AccountReportModule,
            ),
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./pages/home-page/home-page.module').then(
                m => m.HomePageModule,
            ),
    },
    {
        path: '403',
        loadChildren: () =>
            import('./pages/page403/page403.module').then(
                m => m.Page403Module,
            ),
    },
    {
        path: '**',
        loadChildren: () =>
            import('./pages/page404/page404.module').then(
                m => m.Page404Module,
            ),
    },

];

@Injectable({
    providedIn: 'root',
})
export class NavRouteService {
    navRoute: Route;
    navRoutes: NavRoute[];

    constructor(router: Router,
        private permissionsService: SdlPermissionsService) {
        this.navRoute = router.config.find(route => route.path === sideNavPath);
        this.navRoutes = this.navRoute.children
            .filter(route => route.data && route.data.title)
            .reduce((groupedList: NavRoute[], route: NavRoute) => {
                if (route.group) {
                    const group: NavRoute = groupedList.find(navRoute => {
                        return (
                            navRoute.group === route.group &&
                            navRoute.groupedNavRoutes !== undefined
                        );
                    });
                    if (group) {
                        group.groupedNavRoutes.push(route);
                    } else {
                        groupedList.push({
                            group: route.group,
                            iconGroup: route.iconGroup,
                            groupedNavRoutes: [route],
                        });
                    }
                } else {
                    groupedList.push(route);
                }
                return groupedList;
            }, []);
    }

    public getNavRoutes(): NavRoute[] {
        let arrMenu: NavRoute[] = [];
        const menuClonedObject = [];
        this.navRoutes.forEach(menu => {
            menuClonedObject.push(Object.assign({}, menu));
        });

        menuClonedObject.forEach(menu => {
            let menuItem = menu;
            let arrSubMenu: any[] = [];
            menuItem.groupedNavRoutes.forEach(menu2 => {
                menu2.data.permissions.only.forEach(element => {
                    if (this.permissionsService.getPermission(element))
                        arrSubMenu.push(menu2);
                });
            });

            menuItem.groupedNavRoutes = arrSubMenu;
            arrMenu.push(menuItem);

        });
        return arrMenu.filter(x => x.groupedNavRoutes.length > 0);
    }
}
