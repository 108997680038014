 import { SdlTableComponent } from './sdl-table.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material';
import { SDLMaterialModule } from 'src/app/material-module';
import { BrowserModule } from '@angular/platform-browser';
import { TextFilterComponent } from './filters/text-filter/text-filter.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { OptionsCellComponent } from './table-cell/cell-types/options-cell.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { CellDirective } from './table-cell/cell.directive';
import { TextCellComponent } from './table-cell/cell-types/text-cell.component';
import { DateCellComponent } from './table-cell/cell-types/date-cell.component';
import { CellService } from './table-cell/cell-types/cell.service';
import { ColumnFilterService } from './table-cell/cell-types/column-filter.service';
import { StylePaginatorDirective } from './style-paginator.directive';
import { StatusCellComponent } from './table-cell/cell-types/status-cell.component';
import { MatTableResponsiveModule } from './mat-table-responsive.module';
import { InputCellComponent } from './table-cell/cell-types/input-cell-cell.component';
import { SdlPermissionsModule } from 'src/app/permission';
import { SharedModule } from 'src/app/shared.modeule';
import { InputTitleCellComponent } from './table-cell/cell-types/input-title-cell.component';
import { IconCellComponent } from './table-cell/cell-types/icon-cell.component';
import { StylePaginatorLiteDirective } from './style-paginator-lite.directive';

@NgModule({
    declarations: [SdlTableComponent,
        OptionsCellComponent,
        TextFilterComponent,
        DateFilterComponent,
        TableCellComponent,
        CellDirective,
        TextCellComponent,
        DateCellComponent,
        StatusCellComponent,
        InputCellComponent,
        StylePaginatorDirective,
        StylePaginatorLiteDirective,
        InputTitleCellComponent,
        IconCellComponent,


    ],

    exports: [SdlTableComponent],
    imports: [
        CommonModule,
        SDLMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableResponsiveModule,
        SharedModule

    ],
    entryComponents: [
        OptionsCellComponent,
        TextFilterComponent,
        DateFilterComponent,
        TextCellComponent,
        DateCellComponent,
        StatusCellComponent,
        InputCellComponent,
        InputTitleCellComponent,
        IconCellComponent
      ],
      providers: [
        CellService,
        ColumnFilterService
      ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {
    constructor(private readonly cellService: CellService) {
        cellService.registerCell('string', TextCellComponent);
        cellService.registerCell('date', DateCellComponent);
        cellService.registerCell('options', OptionsCellComponent);
        cellService.registerCell('status', StatusCellComponent);
        cellService.registerCell('input', InputCellComponent);
        cellService.registerCell('title', InputTitleCellComponent);
        cellService.registerCell('icon', IconCellComponent);
      }


}
