import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatDividerModule, MatListModule, MatExpansionModule } from '@angular/material';
import { SdlSidebarComponent } from './sdl-sidebar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SdlSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
  ],
  exports: [
    SdlSidebarComponent,
  ]
})
export class SdlSidebarModule { }
