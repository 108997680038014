import { Component, OnInit } from '@angular/core';
import { SdlMenuService } from '../../services/sdl-menu/sdl-menu.service';
import { mainContentAnimation } from './animation';
import { NavRoute } from 'src/app/nav-routing';
import { AuthService } from 'src/app/auth/auth.service';
import { NavigationService, Page } from '../../services/navigation/navigation.service';

@Component({
  selector: 'sdl-menu',
  templateUrl: './sdl-menu.component.html',
  styleUrls: ['./sdl-menu.component.scss'],
  animations: [
    mainContentAnimation(),
  ]
})
export class SdlMenuComponent implements OnInit {
  arrMenuMenu: NavRoute[]=[];

  sidebarState: string;

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private sidebarService: SdlMenuService,
  ) { }

  ngOnInit() {
    this.sidebarService.sidebarStateObservable$
      .subscribe((newState: string) => {
        this.sidebarState = newState;
      });

      this.authService.menuSubject.subscribe(res => {
        this.arrMenuMenu=this.getNavigationItems();
    });
  }

  public getNavigationItems(): NavRoute[] {
    return this.navigationService.getNavigationItems();
}

public getActivePage(): Page {
    return this.navigationService.getActivePage();
}



public getPreviousUrl(): string[] {
    return this.navigationService.getPreviousUrl();
}


}
