import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule, MatProgressBarModule, MatDividerModule } from '@angular/material';
import { SdlToggleSidebarModule } from '../sdl-toggle-sidebar/sdl-toggle-sidebar.module';
import { SdlHeaderComponent } from './sdl-header.component';
import { SdlUserModule } from '../../sdl-user/sdl-user.component.module';

@NgModule({
  declarations: [SdlHeaderComponent],
  imports: [
    CommonModule,
    SdlToggleSidebarModule,
    MatToolbarModule,
    SdlUserModule,
    MatProgressBarModule,
    MatDividerModule
  ],
  exports: [
    SdlHeaderComponent,
  ]
})
export class SdlHeaderModule { }
