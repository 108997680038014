
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material';
import { SDLMaterialModule } from 'src/app/material-module';
import { BrowserModule } from '@angular/platform-browser';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SdlUserComponent } from './sdl-user.component';
import { TruncateTextPipe } from '../../pipes/truncateTextPipe';

@NgModule({
    declarations: [SdlUserComponent,TruncateTextPipe],

    exports: [SdlUserComponent],
    imports: [
        CommonModule,
        SDLMaterialModule,
        FormsModule,
        ReactiveFormsModule

    ],
    entryComponents: [

      ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SdlUserModule {

}
