import { NgModule, ModuleWithProviders } from '@angular/core';
import { SdlPermissionsDirective } from './directive/permissions.directive';
import { SdlPermissionsService, USE_PERMISSIONS_STORE } from './service/permissions.service';
import { SdlPermissionsGuard } from './router/permissions-guard.service';
import { NgxRolesService, USE_ROLES_STORE } from './service/roles.service';
import { SdlPermissionsStore } from './store/permissions.store';
import { NgxRolesStore } from './store/roles.store';
import { SdlPermissionsAllowStubDirective } from './testing/permissions-allow.directive.stub';
import { SdlPermissionsRestrictStubDirective } from './testing/permissions-restrict.directive.stub';
import { SdlPermissionsConfigurationService, USE_CONFIGURATION_STORE } from './service/configuration.service';
import { SdlPermissionsConfigurationStore } from './store/configuration.store';

export * from './store/roles.store';
export * from './store/permissions.store';
export * from './store/configuration.store';

export * from './directive/permissions.directive';

export * from './service/permissions.service';
export * from './service/roles.service';
export * from './service/configuration.service';

export * from './router/permissions-guard.service';

export * from './model/permissions-router-data.model';
export * from './model/role.model';

export * from './testing/permissions-allow.directive.stub';
export * from './testing/permissions-restrict.directive.stub';

export * from './enums/predefined-strategies.enum';

export interface SdlPermissionsModuleConfig {
    // isolate the service instance, only works for lazy loaded modules or components with the "providers" property
    rolesIsolate?: boolean;
    permissionsIsolate?: boolean;
    configurationIsolate?: boolean;
}


@NgModule({
    imports: [],
    declarations: [
        SdlPermissionsDirective
    ],
    exports: [
        SdlPermissionsDirective
    ]
})
export class SdlPermissionsModule {
    static forRoot(config: SdlPermissionsModuleConfig = {}): ModuleWithProviders<SdlPermissionsModule> {
        return {
            ngModule: SdlPermissionsModule,
            providers: [
                SdlPermissionsStore,
                NgxRolesStore,
                SdlPermissionsConfigurationStore,
                SdlPermissionsService,
                SdlPermissionsGuard,
                NgxRolesService,
                SdlPermissionsConfigurationService,
                {provide: USE_PERMISSIONS_STORE, useValue: config.permissionsIsolate},
                {provide: USE_ROLES_STORE, useValue: config.rolesIsolate},
                {provide: USE_CONFIGURATION_STORE, useValue: config.configurationIsolate},
            ]
        };
    }

    static forChild(config: SdlPermissionsModuleConfig = {}): ModuleWithProviders<SdlPermissionsModule> {
        return {
            ngModule: SdlPermissionsModule,
            providers: [
                {provide: USE_PERMISSIONS_STORE, useValue: config.permissionsIsolate},
                {provide: USE_ROLES_STORE, useValue: config.rolesIsolate},
                {provide: USE_CONFIGURATION_STORE, useValue: config.configurationIsolate},
                SdlPermissionsConfigurationService,
                SdlPermissionsService,
                NgxRolesService,
                SdlPermissionsGuard
            ]
        };
    }
}

@NgModule({
    imports: [],
    declarations: [
        SdlPermissionsAllowStubDirective
    ],
    exports: [
        SdlPermissionsAllowStubDirective
    ]
})
export class SdlPermissionsAllowStubModule {
}


@NgModule({
    imports: [],
    declarations: [
        SdlPermissionsRestrictStubDirective
    ],
    exports: [
        SdlPermissionsRestrictStubDirective
    ]
})
export class SdlPermissionsRestrictStubModule {
}


