import { Component, Input } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';

@Component({
    selector: 'mdt-status-cell',

    templateUrl: './status-cell.component.html'

})
export class StatusCellComponent implements CellComponent {
    @Input() column: ColumnConfig;
    @Input() row: object;

    constructor(){

    }

}