import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { StorageService } from '../core/services/storage/storage.service';
import { StorageKey } from '../core/services/storage/storage.model';
import { JwtHelperService } from "@auth0/angular-jwt";
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { map, take, switchMap, catchError } from 'rxjs/operators';
import { SdlPermissionsService } from '../permission';
import { Observable, BehaviorSubject, Subscription, timer } from 'rxjs';
import { NotificationService } from '../core/services/notifications/notificationService';
import { Router } from '@angular/router';

const { AUTH_TOKEN } = StorageKey;
const { TOKEN_EXP } = StorageKey;
const helper = new JwtHelperService();
@Injectable({
    providedIn: 'root',
})
@Injectable({
    providedIn: 'root',
})
export class AuthService {//extends CrudService {
    endpoint = 'auth/login';
    token: any;
    redirectUrl: string;
    static advisorTimer: Observable<number>;
    static advisorCounter: Subscription;
    static logoutTimer: Observable<number>;
    static logoutCounter: Subscription

    public usersSubject = new BehaviorSubject(null);
    public menuSubject = new BehaviorSubject(null);
    public permissionSubject = new BehaviorSubject(null);
    constructor(private http: HttpClient,
        private storage: StorageService,
        private router: Router,
        private notifications: NotificationService,
        private permissionsService: SdlPermissionsService) {
        //super(http);
        this.token = this.storage.read(AUTH_TOKEN) || '';
    }

    public login(form: FormGroup) {

        return this.http.post<any>(`${environment.apiUrl}/auth/login`, form)
            .pipe(

                map(ttoken => {

                    this.permissionsService.flushPermissions();
                    this.token = ttoken;//.token.access_token;
                    this.storage.save(AUTH_TOKEN, this.token);
                    let data = helper.decodeToken(this.token.token.access_token);

                    this.changeToken(this.token);

                    this.permissionsService.loadPermissions(data.Permission);
                    this.permissionsService.addPermission(data.Permission);

                    this.permissionSubject.next(data);

                    return ttoken;
                }));

    }

    refresh(token: any) {
        const tokenParam = {
            Token: token
        }
        const authURL: string = '/auth/refresh-token';
        let LocalURL = environment.apiUrl + authURL;
        return this.http.post(LocalURL, tokenParam).pipe(
            take(1),
            map(res => {
                return res;
            }),
            map(ttoken => {

                this.permissionsService.flushPermissions();
                this.token = ttoken;//.token.access_token;
                this.storage.save(AUTH_TOKEN, this.token);

                let data = helper.decodeToken(this.token.token.access_token);

                this.changeToken(this.token);

                this.permissionsService.loadPermissions(data.Permission);
                this.permissionsService.addPermission(data.Permission);

                this.permissionSubject.next(data);

                return ttoken;

            })
        );
    }

    changeToken(token: any) {


        let data2 = helper.decodeToken(this.token.token.refresh_token);
        var momentOfTime = new Date();
        momentOfTime.setTime(parseInt(data2.exp) * 1000);
        this.storage.save(TOKEN_EXP, momentOfTime);

        this.notifications.clear();

        let time = this.storage.read(TOKEN_EXP);

        if (time) {
            var eventStartTime = new Date();
            var eventEndTime = new Date(time);
            var duration = eventEndTime.valueOf() - eventStartTime.valueOf();
        }
        if (AuthService.advisorTimer) {
            AuthService.advisorCounter.unsubscribe();
        }
        AuthService.advisorTimer = timer((duration - (60 * 1000)));
        AuthService.advisorCounter = AuthService.advisorTimer.subscribe(r => {
            this.notifications.info('Falta 1 minuto para que expire la sesión', true);
        });

        if (AuthService.logoutTimer) {
            AuthService.logoutCounter.unsubscribe();
        }
        AuthService.logoutTimer = timer(duration);
        AuthService.logoutCounter = AuthService.logoutTimer.subscribe(r => {
            this.notifications.clear();
            this.notifications.warn('Su sesión ha expirado!', true);
            // this.router.navigate(['/login']);
            this.logout();
        });
    }

   
    public getToken(): any {
        if (this.token)
            return this.token;
        else
            return this.storage.read(AUTH_TOKEN);
    }

    public getTokenByRefresh(): any {
        return this.builderRefreshToken();
    }

    builderRefreshToken() {
        let token: any;
        if (this.token)
            token = this.token;
        else
            token = this.storage.read(AUTH_TOKEN);

        if (token) {
            return {
                Access_token: token.token.access_token,
                Refresh_token: token.token.refresh_token,
                Expires_in: token.token.expires_in
            }
        }
    }

    public logout() {
        this.token = '';

        this.storage.remove(TOKEN_EXP);
        this.router.navigate(['login'], { replaceUrl: true });

        this.storage.save(AUTH_TOKEN, "");
        this.storage.remove(AUTH_TOKEN);
        sessionStorage.remove(AUTH_TOKEN);

        //Detengo las suscripciones de control de cierre de session
        if (AuthService.advisorTimer) {
            AuthService.advisorCounter.unsubscribe();
        }

        if (AuthService.logoutTimer) {
            AuthService.logoutCounter.unsubscribe();
        }

        this.notifications.clear();
    }

    public isLogged(): boolean {
        return this.token;
    }
}
