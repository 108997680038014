import { Component, Input, Output, EventEmitter, AfterContentInit, AfterViewChecked, AfterViewInit, AfterContentChecked } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'ld-input-cell',
    templateUrl: './input-cell.component.html',
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class InputCellComponent implements CellComponent, AfterContentChecked {
    @Input()
    column: ColumnConfig;

    @Input()
    row: any;

    parTipo: number = 1;
    type: string = "text";

    //Emite un evento callback
    @Output() onSaveEdit = new EventEmitter<any>();


    constructor() {


    }
    ngAfterContentChecked(): void {
        this.parTipo = this.row.parTipo

        switch (this.parTipo) {
            case 1:
                this.type = "number";
                break;
            case 2:
                this.type = "text";
                break;
            case 3:
                this.type = "text";
                break;
            default:
                this.type = "text";
                break;
        }
    }

    onEdit?: EventEmitter<any>;
    onRemove?: EventEmitter<any>;




    saveEdit() {

        this.onSaveEdit.next(this.row);
    }


}