import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class LogoComponent implements OnInit {
    constructor( private router: Router,) {}
@Input() isOpen:boolean=true;
    ngOnInit() {}

    onResize(event) {
        if (event.target.innerWidth <= 768)
            this.isOpen = false;
        else
            this.isOpen = true;
    }
    home()
    {
        this.router.navigate(['home'], { replaceUrl: true });
    }
}
