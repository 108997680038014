import { Component, OnInit, Input } from '@angular/core';
import { SdlMenuService } from 'src/app/core/services/sdl-menu/sdl-menu.service';
import { labelAnimation, iconAnimation, sidebarAnimation } from '../animation';
import { NavRoute } from 'src/app/nav-routing';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'sdl-sidebar',
  templateUrl: './sdl-sidebar.component.html',
  styleUrls: ['./sdl-sidebar.component.scss'],
  animations: [
    sidebarAnimation(),
    iconAnimation(),
    labelAnimation(),
  ]
})
export class SdlSidebarComponent implements OnInit {
  @Input() arrMenuMenu: NavRoute[] = [];
  sidebarState: string;
  openMenu = false;

  constructor(
    private sidebarService: SdlMenuService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.sidebarService.sidebarStateObservable$.
      subscribe((newState: string) => {
        this.sidebarState = newState;

        this.openMenu = !this.openMenu?true:false;

      });
  }

  public isSelected(navItem: NavRoute) {
    return this.navigationService.getSelectedNavigationItem() === navItem;
  }

  public shouldOpenGroup(groupedNavItems: NavRoute[]) {
    return groupedNavItems.reduce((shouldOpen, navItem) => {
      return (shouldOpen || this.isSelected(navItem))
    }, false);
  }

  clickTitle(event) {
    if (this.sidebarState === 'close') {
      this.sidebarService.toggle();
    }
  }
}
