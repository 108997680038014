import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private localToken: any;
  constructor(private authService: AuthService,
    private injector: Injector ) { }
  intercept(request: HttpRequest<any>,
    next: HttpHandler):
    Observable<HttpEvent<any>> {
    try {

      const token: any = this.authService.getToken();
      if (token) {
        this.localToken = JSON.parse(JSON.stringify(token));
        request = request.clone({ headers: request.headers.set('Authorization', 'bearer ' + this.localToken.token.access_token) });
      }

      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            return this.handle401Error(request, next);
          }
         // const appErrorHandler = this.injector.get(ErrorHandler);
         // appErrorHandler.handleError(error);
          return throwError(error);
        })
      );
    }
    catch (err) {
      //alert(err);
    }
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone();
      clone = clone.clone({ headers: request.headers.set('Authorization', 'bearer ' + token) });
      if (!clone.headers.has('Content-Type')) {
        clone = clone.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      return clone;
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refresh(this.authService.getTokenByRefresh()).pipe(
      switchMap((to: any) => {
        request = request.clone({ headers: request.headers.set('Authorization', 'bearer ' + to.access_token) });
        if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        return next.handle(this.addToken(request, to.token.access_token));
      }),
      catchError(err => {
        this.authService.logout();
       // const appErrorHandler = this.injector.get(ErrorHandler);
        //appErrorHandler.handleError(err);
        return throwError(null)
      })
      );
  }
}
