import { Component, ComponentFactoryResolver, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { CellDirective } from './cell.directive';
import { CellService } from './cell-types/cell.service';
import { CellComponent } from './cell-types/cell.component';
import { ColumnConfig } from '../column-config.model.ts';

@Component({
    selector: 'mdt-table-cell',
    templateUrl: './table-cell.component.html'

})
export class TableCellComponent implements OnInit {
    @ViewChild(CellDirective, { static: true }) cellHost: CellDirective;

    @Input() row: object;
    @Input() column: ColumnConfig;
   @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
   @Output() onView: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveEdit: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private readonly cellService: CellService,
        private readonly componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        this.initCell();
    }

    initCell() {
        const cellComponent = this.cellService.getCell(this.column.type);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(cellComponent);
        const viewContainerRef = this.cellHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);


        const cell = componentRef.instance as CellComponent;
        cell.row = this.row;
        cell.column = this.column;

        if (this.column.type==="options") {

            componentRef.instance.onRemove.subscribe(res => {
                this.onRemove.next(res);
            });
            componentRef.instance.onEdit.subscribe(res => {
                this.onEdit.next(res);
            });

            componentRef.instance.onView.subscribe(res => {

                this.onView.next(res);
            });
        }

        if (this.column.type==="input") {

            componentRef.instance.onSaveEdit.subscribe(res => {
                this.onSaveEdit.next(res);
            });

        }

    }



}