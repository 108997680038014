import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, enableProdMode, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/services/http/interceptor.service';
import { TableModule } from './core/components/sdl-table/table.module';
import { ColumnFilterService } from './core/components/sdl-table/table-cell/cell-types/column-filter.service';
import { MAT_DATE_LOCALE } from '@angular/material';
import { HttpToolbarLoadingInterceptor } from './core/http-interceptor/http-toolbar.interceptor';
import { SdlPermissionsModule } from './permission';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SdlMenuModule } from './core/components/sdl-menu/sdl-menu.module';
import { SdlAlertComponent } from './core/components/sdl-alert/sdl-alert.component';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './core/http-interceptor/http-timeout-interceptor';
import { ConfigService } from './core/services/settings/configService';
import { BlobErrorHttpInterceptor } from './core/http-interceptor/http-blobError.Interceptor';


declare global {
  interface Window {
    WebComponents: {
      ready: boolean;
    };
  }
}


export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

@NgModule({
  declarations: [AppComponent, SdlAlertComponent],
  entryComponents: [
    SdlAlertComponent
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SdlMenuModule,
    HttpClientModule,
    TableModule,
    SdlPermissionsModule.forRoot(),
    TooltipModule.forRoot(),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [

    ColumnFilterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpToolbarLoadingInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    //{ provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
    [{ provide: DEFAULT_TIMEOUT, useValue: 30000 }],

  ],
  bootstrap: [AppComponent],
})


export class AppModule {

  constructor() {



  }



}
