import { Component, Input, OnInit } from '@angular/core';
import { CellComponent } from './cell.component';
import { ColumnConfig } from '../../column-config.model.ts';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };


@Component({
    selector: 'mdt-date-cell',
    template: '{{ strDate }}',
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
      ],
})
export class DateCellComponent implements CellComponent, OnInit {
    @Input() column: ColumnConfig;
    @Input() row: object;

    strDate="";
    dateFormat = 'DD/MM/YYYY HH:mm';

    ngOnInit() {
        if (this.column.optionConfig) {
            if (this.column.optionConfig.dateFormat) {
                this.dateFormat = this.column.optionConfig.dateFormat;
            }
        }

        if(this.row[this.column.name])
        this.strDate=moment(this.row[this.column.name]).format(this.dateFormat);
    }
}