import { Component, OnInit } from '@angular/core';
import { SdlPermissionsService } from './permission';
import { StorageService } from './core/services/storage/storage.service';
import { StorageKey } from './core/services/storage/storage.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth/auth.service';
import { timer } from 'rxjs';
import { NotificationService } from './core/services/notifications/notificationService';
import { Router } from '@angular/router';

const { AUTH_TOKEN } = StorageKey;
const { TOKEN_EXP } = StorageKey;
const helper = new JwtHelperService();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    title = 'Backoffice Of. Virtual';
    constructor(private storage: StorageService,
        private permissionsService: SdlPermissionsService,
        private authServices: AuthService,
        private router: Router,
        private notifications: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.authServices.permissionSubject.subscribe(res => {
            this.loadPermission();
        });
        this.loadPermission();
    }

    loadPermission() {
        let token = this.storage.read(AUTH_TOKEN);
        if (!token) return;
        let data = helper.decodeToken(token.token.access_token);
        if (data) {
            this.permissionsService.flushPermissions();
            this.permissionsService.addPermission(data.Permission);
            this.authServices.usersSubject.next(data);
            this.authServices.menuSubject.next(data);
        }
    }
}
