import { Injectable } from '@angular/core';
import { StorageKey } from './storage.model';
const { AUTH_TOKEN } = StorageKey;
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private storage: Storage;

    constructor() {
        this.storage = localStorage;
    }

    public save(key: StorageKey, value: any) {

        if (key === AUTH_TOKEN)
            sessionStorage.setItem(AUTH_TOKEN,  JSON.stringify(value));

        else {
            value = JSON.stringify(value);
            this.storage.setItem(key, value);
        }
    }

    public read(key: StorageKey): any {
        if (key === AUTH_TOKEN) {
            const value =  sessionStorage.getItem(key);
            return value?JSON.parse(value):null;
        }
        else {
            const value = this.storage.getItem(key);
            return JSON.parse(value);
        }

    }

    public remove(key: StorageKey) {
        return this.storage.removeItem(key);
    }

    public clear() {
        this.storage.clear();
    }
}
