import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SdlPermissionsModule } from './permission';

@NgModule({
  exports: [
      CommonModule,
      SdlPermissionsModule
  ]
})
export class SharedModule { }